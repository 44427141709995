import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { PartnerRuleNames, PartnerType } from '../appConstant'

export default function FilterBox(props) {

    const accountsOption = props?.accounts?.map((item) => {
        return {
            key: item?.key,
            text: item?.text,
            value: item?.accountName
        }
    })
    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "10px", marginTop: "60px" }}><h3>Filters:</h3></div>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <label style={{ fontWeight: "bold" }}>Rule Name</label>
                    <Dropdown
                        name={'rule-name'}
                        placeholder='Select Rule Name'
                        selection
                        clearable
                        options={PartnerRuleNames}
                        onChange={(e, d) => {
                            props?.setRuleName(d.value)
                            props?.setPdfRuleName(PartnerRuleNames?.find(item=>item?.value===d.value)?.text);
                            if(d?.value===""){
                                props?.setSelectedType("")
                            }
                        }}
                        value={props?.ruleName}
                    />
                </div>
                {props?.ruleName !=="" ? (
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                    <label style={{ fontWeight: "bold" }}>Trusted Partner Type</label>
                    <Dropdown
                        name={'partner-type'}
                        placeholder='Select type'
                        selection
                        clearable
                        options={props?.ruleName?.toLowerCase()?.includes("iam") ? PartnerType?.iam : (props?.ruleName?.toLowerCase()?.includes("s3") ? PartnerType?.s3 : [])}
                        onChange={(e, d) => {
                            if(d?.value?.toLowerCase()?.includes("global")){
                                props?.setSelectedAccount("")
                            }
                            props?.setSelectedType(d.value)
                        }}
                        value={props?.selectedType}
                    />
                </div>
                ) :null}
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <label style={{ fontWeight: "bold" }}>Trusted Partner Name </label>
                    <Dropdown
                        name={'partner-name'}
                        placeholder='Select or search partner'
                        selection
                        search
                        clearable
                        options={props?.partnersOption.filter(_item => {
                            return props?.currentData?.some((value) => value?.GroupKey?.toLowerCase()?.includes(_item?.value?.toLowerCase()))
                        })}
                        value={props?.partnerName}
                        onChange={(e, v) => props?.setPartnerName(v?.value)}
                    />
                </div>
                {!props?.selectedType?.toLowerCase()?.includes("global") ? (
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <label style={{ fontWeight: "bold" }}>Verisk Accounts</label>
                        <Dropdown
                            name={'verisk-account'}
                            placeholder='Select or search account'
                            selection
                            search
                            clearable
                            options={accountsOption?.filter(_item => {
                                return props?.copyMetadata?.some((value) => value?.Associations?.includes(_item?.value))
                            })}
                            value={props?.selectedAccount}
                            onChange={(e, d) => {
                                props?.setSelectedAccount(d.value)
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    )
}
