/* eslint-disable no-mixed-operators */
import React, { Component } from "react";
import {
  Form,
  Segment,
  Confirm,
  Button,
  Message,
  Modal,
  Header,
  Popup,
} from "semantic-ui-react";
import ToastMessage from "../../../../ReUsableComponent/ToastMessageComp";
import { commercialCloud, labelAws, labelAwsUsGov } from "../../../../../Config/appConstant";
// toast message

class ResourceExceptionEditForm extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    //console.log('Component did update!')

    this.props.setEditData();
  }

  createConfirmMsg = () => {
    return "Do you want to update the exception " + this.props.resourceId + "?";
  };

  disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};


  render() {
    const findButtonStatus =
      this.props.showUpdateButton || this.props.checkUpdateButtton();
    const findButtonStatusPera =
      this.props.showUpdateButton || this.props.checkUpdateButttonPeraId();

    let getAccountName = this.props.accountOptions?.find((item)=>item.value === this.props.accId)?.accountName


    return (
      <Modal
        closeIcon
        open={this.props.openModal && this.props.openOrCloseModal}
        onClose={() => {
          this.props.openOrCloseModalFunc(false)}}
      >
        <Segment
          size="mini"
          style={{
            padding: "30px",
            borderColor: "blue",
            borderStyle: "Solid",
            margin: "6px",
          }}
        >
          <Header as="h3" color="blue">
            Edit Resource Exception
          </Header>
          {this.props.onToastMsg && (
            <ToastMessage
              toastMessage={this.props.toastMessage}
              colour={this.props.toastColour}
              dismissToastMessage={this.props.dismissToastMessage}
            />
          )}
          {/* <Segment.Group> */}
          <Form style={{ width: "1300px" }}>
            <Form.Group widths="equal">
            <Popup
              content={'Read Only'}
              trigger={
                <Form.Input
                fluid
                readOnly
                label={'Rule ID'}
                value={this.props.ruleId}
                name="ruleId"
                style={{ height: "50px"}}
              />
              }/>
              <Popup
              content={'Read Only'}
              trigger={
                <Form.Input
                fluid
                readOnly
                label={'Rule Name'}
                value={this.props.ruleName}
                name="ruleName"
                style={{ height: "50px"}}
              />
              }
              />
              
            </Form.Group>
            <Form.Group widths="equal">
            <Popup
              content={'Read Only'}
              trigger={
                <Form.Input
                fluid
                readOnly
                label={'Account Name'}
                value={getAccountName}
                name="accountName"
                style={{ height: "50px"}}
              />
              }/>
              <Popup
              content={'Read Only'}
              trigger={
                <Form.Input
                fluid
                readOnly
                label={'Account ID'}
                value={this.props.accId}
                name="accId"
                placeholder="Enter 12 digit account ID"
                style={{ height: "50px"}}
              />
              }/>
              
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
            <Popup
              content={'Read Only'}
              trigger={
                <Form.Input
                fluid
                label="Resource ID/ARN"
                readOnly
                value={this.props.resourceId.includes('+') ? this.props.resourceId?.split('+')[0] : this.props.resourceId}
                name="resourceId"
                style={{ height: "50px"}}
              />
              }/>
            
              {
                (this.props.resourceRegionName && this.props.isResourceRegionNameExists) ? 
                 (
                  <Popup
                  content={'Read Only'}
                  trigger={
                  <Form.Input
                    fluid
                    readOnly
                    label={'Region'}
                    value={this.props.resourceRegionName}
                    name="resourceRegion"
                    style={{ height: "50px"}}
                  />
              }/>
                  
                 )
               :
                (
                  <Form.Select
                    required
                    label="Region"
                    options={this.props.resourceRegion}
                    value={this.props.resourceRegionName}
                    search
                    placeholder="Select region"
                    name="resourceRegion"
                    floated
                    loading={(this.props.resourceRegion !== undefined && this.props.resourceRegion.length>0) ? false : true}
                    onChange={this.props.handleResourceRegionName}
                    style={{ height: "floated", height: "50px", width: "390px" }}
                  />
                )
              }
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
            <Popup
              content="Read Only"
              trigger={<Form.Input
              fluid
              label={'Additional Entity'}
              placeholder=""
              className="additional_entity"
              readOnly
              value={this.props.resourceId.includes('+') ? this.props.resourceId?.split('+')[1] : 'N/A'}
              name="resourceChecksum"
              style={{ height: "50px"}}
            />}
            />
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
              <Form.Input
                fluid
                label="ServiceNow Ticket ID"
                placeholder="Ticket ID"
                value={this.props.ticketId}
                name="ticketId"
                onChange={this.props.handleChange}
                style={{ height: "50px" }}
                maxLength="11"
                required={
                  this.props.ruleSeverity === "High" ||
                  this.props.ruleSeverity === "Critical" ||
                  this.props.ruleSeverity === "Info" ||
                  this.props.ruleSeverity === "Medium" ||
                  this.props.ruleSeverity === "Low"
                }
              />
            </Form.Group>
            <Form.Group widths="equal" style={{ marginTop: "25px" }}>
              <Form.Input
                fluid
                label="Recertification Date"
                type="date"
                name="lastRecertificationDate"
                value={this.props.lastRecertificationDate}
                onChange={this.props.handleChange}
                style={{ height: "50px" }}
                min={this.disablePastDate()}
              />
              <Form.Input
                fluid
                label="Expiry Date"
                type="date"
                name="expiryDate"
                min={this.props.minDate}
                value={this.props.expiryDate}
                onChange={this.props.handleChange}
                style={{ height: "50px" }}
              />
            </Form.Group>
            <Form.Group style={{ marginTop: "25px" }}>
              {!this.props.ruleSeverity === "High" ||
              !this.props.ruleSeverity === "Critical" ||
              this.props.ruleSeverity === "Info" ||
              this.props.ruleSeverity === "Medium" ||
              this.props.ruleSeverity === "Low" ? (
                <Form.Button
                  disabled={
                    this.props.accId < 1 ||
                    this.props.ruleId < 1 ||
                    (this.props.errors && this.props.errors.length) ||
                    findButtonStatus
                  }
                  size="medium"
                  style={{ backgroundColor: "#13a6d8" }}
                  onClick={this.props.handleSubmit}
                >
                  Update
                </Form.Button>
              ) : (
                <Form.Button
                  disabled={
                    this.props.accId < 1 ||
                    this.props.ruleId < 1 ||
                    (this.props.errors && this.props.errors.length) ||
                    (findButtonStatus)
                  }
                  size="medium"
                  style={{ backgroundColor: "#13a6d8" }}
                  onClick={this.props.handleSubmit}
                >
                  Update
                </Form.Button>
              )}
              <Confirm
                open={this.props.open}
                content={`Are you sure you want to update in ${this.props.selectCloud === commercialCloud ? labelAws.label : labelAwsUsGov.label}?`}
                onCancel={this.props.handleCancel}
                onConfirm={this.props.handleConfirm}
              />
            </Form.Group>
            <Message
              visible={!!this.props.errors && this.props.errors.length}
              warning
              header="Please correct the following issues: "
              list={this.props.errors}
            />
            {this.props.ruleSeverity === "High" ||
            this.props.ruleSeverity === "Critical" ||
            this.props.ruleSeverity === "Info" ||
            this.props.ruleSeverity === "Medium" ||
            this.props.ruleSeverity === "Low" ? (
              <>
                {findButtonStatus ? (
                  <Message>
                    <Message.Header>
                      Please correct the following issues:
                    </Message.Header>
                    <Message.List
                      items={["New ServiceNow Ticket ID Required For Recertification."]}
                    />
                  </Message>
                ) : (
                  ""
                )}
              </>
            ) : null}
          </Form>
        </Segment>
      </Modal>
    );
  }
}

export default ResourceExceptionEditForm;
