import axios from "axios";
import { commercialCloud } from "../../../../Config/appConstant";
import config from "../../../../Config/Config";


export const getAccountsList = (selectedCloud, token) => new Promise((resolve,reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
        headers: {
            'Authorization': token
        }
    };
    axios.get(selectedUrl + 'account/account-list', options)
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
})

export const handleRootAccess = (selectedCloud, token, payload) => new Promise((resolve, reject)=>{
    const selectedUrl = selectedCloud?.toLowerCase() === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
        headers: {
            'Authorization': token
        }
    };
    axios.post(selectedUrl + 'cram', payload, options)
        .then(res => {
            resolve(res)
        })
        .catch(function (error) {
            reject(error)
        })
})