import React, { Component } from 'react';
import ResourceExceptionSearchForm from './ResourceExceptionSearchForm';
import axios from 'axios';
import SortableTable from "../../../ReUsableComponent/SortableTable";
import ResourceExceptionEditFormContainer from './Edit/ResourceExceptionEditFormContainer';
import ResourceExceptionDelete from './Delete/ResourceExceptionDelete';
import * as yup from 'yup';
import config from '../../../../Config/Config';
import { commercialCloud, localStorageCloudValue } from '../../../../Config/appConstant';
//toast message

class ResourceExceptionSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: '0000',
      ruleSelected:'',
      accId:'',
      resourceId:'',
      optionlistdata:[],
      searchStatus:'',
      searchResultData:[],
      acc_options:[],
      data:[],
      toEdit:'no',
      dataEdited:'no',
      toDelete:'no',
      dataToEdit:'',
      dataToDelete:'',
      openModal:false,
      headers:["RuleId","RuleName","AccountId","Exception","ExceptionRegion","ExceptionStatus","ServiceNowTicketId","ApprovalDate","LastRecertificationDate","ExpiryDate"],
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      isSearchingException:false,
      selectCloud:'',
      openModal:true

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataFromApi = this.readDataFromApi.bind(this);
    this.handleEditPopUpClose=this.handleEditPopUpClose.bind(this);
    this.handleDeleteConfirm=this.handleDeleteConfirm.bind(this);
    this.clearClick=this.clearClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
    this.getSeletedCloud = this.getSeletedCloud.bind(this)
  }

  schema = yup.object().shape({
    accId: yup.string()
    .required("AccountID is a required field")
    .matches(/^[0-9]+$/, "AccountID must be only digits")
    .min(12, "AccountID must be exactly 12 digits")
    .max(12, "AccountID must be exactly 12 digits")
  });
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => this.schema.validate(this.state, { abortEarly: false })
      .then(valid => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
      .catch(err => this.setState({ errors: err.errors, errorPaths: err.inner.map(i => i.path) })))} //called if any field is invalid

  handleDropDownChange=(event,data)=>{
    this.setState({[data.name]: data.value});
  }

  dataEditedLoading=()=>{
    this.getDataForSearch();
  }

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }

  handleSubmit=(event)=>{
    if (this.state.ruleId === '0000'){
      this.setState({
        onToastMsg:true,
        toastMessage:'Select a rule id and click on search',
        toastColour:'red'
      })
    }
    else {
        this.getDataForSearch();
    }
  }

  readDataAccountIdFromApi=()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };
    axios.get(selectedUrl+'account/account-list',options)
      .then(res => {
        var acc_list_created = res.data.body.data;
        this.setState({['acc_options']:acc_list_created});
      })
      .catch(function (error) {
        console.error(error);
    })
  }

getDataForSearch=async()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    this.setState({isSearchingException:true,onToastMsg:false})
    const data = {
      params: {RuleId:parseInt(this.state.ruleId),AccountId:this.state.accId,Exception:this.state.resourceId},
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };
    await axios.get(selectedUrl+'exception/RESOURCE/search',data)
      .then(res => {

        var ruleidList = res.data.body.data?.filter((item)=>item?.ExceptionStatus!=="EXCEPTION_DELETED")
        var statusCode = res.data.status_code;

        if (statusCode === 200){
          this.setState({['data']: ruleidList})
          this.setState({['searchStatus']:''})
          this.setState({['searchStatus']:statusCode})
          this.setState({['searchResultData']:ruleidList,isSearchingException:false})
        }
        else{
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'red',
            searchResultData:[],
            isSearchingException:false
          })
        }
      })
      .catch(function (error) {
        console.error(error);
    })
    //console.log(this.state)
  }
  getSeletedCloud = (data)=> data[`${this.state.selectCloud?.split('_')[0].toLowerCase()}Cloud`]
  readDataFromApi=()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };

    axios.get(selectedUrl+'configrules',options)
      .then(res => {
        var filteredData = res.data.body.data;
        var ruleid_list = filteredData?.filter((item)=>item?.ExceptionType!=="Not_Applicable" && (item?.CloudCompatibility !== undefined && item?.CloudCompatibility[this.state.selectCloud]))
        let option_list=[];
        ruleid_list.map(myFunction)

        function myFunction(element) {
          var new_element={};
          new_element['key'] =element['RuleId']
          new_element['text'] =element['RuleId']+" "+element['RuleName']
          new_element['value'] =element['RuleId']
          option_list.push(new_element);
        }
        this.setState({['optionlistdata']:option_list});
      })
      .catch(function (error) {
        console.error(error);
    })
  }

  handleEdit=(selectedRuleData)=>{
    // //console.log(selectedRuleData)
    this.setState({['toEdit']:'yes'});
    this.setState({['dataToEdit']:selectedRuleData});
    this.setState({['openModal']:true})
  }

  handleDelete=(selectedRuleData)=>{
    this.setState({['toDelete']:'yes'});
    this.setState({['openDeletePopUp']:true})
    this.setState({['dataToDelete']:selectedRuleData});
    this.setState({['resourceId']:selectedRuleData['Exception']})
  }

  handleDeletePopUpClose=()=>{
    this.setState({['toDelete']:'no',openDeletePopUp: false})
  }

  handleEditPopUpClose=()=>{
    this.setState({['openModal']:false});
    this.setState({['toEdit']:'no'});
  }

  handleDeleteConfirm=async()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const data = {
      params: {
        RuleId:parseInt(this.state.ruleId),
        AccountId:this.state.accId,
        Exception:this.state.resourceId,
        LastModifiedBy: this.props.authState.accessToken.claims.sub,
        ExceptionStatus:'EXCEPTION_DELETED'
      },
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };

    await axios.delete(selectedUrl+'exception/RESOURCE', data)
    .then(res => {
  
      if(res.data.status_code === 204){
        this.handleDeletePopUpClose();
        this.setState({
          onToastMsg:true,
          toastMessage:res.data.body.message,
          toastColour:'green',
          ruleId:'0000',
          searchStatus:''
        })
        this.readDataFromApi();
      }
      else{
        this.setState({
          onToastMsg:true,
          toastMessage:res.data.body.message,
          toastColour:'red'})
      }
      // this.clearClick()
    })
    .catch(function (error) {
      console.error(error);
  })
  }

  clearClick=()=>{
    this.setState({
    ruleId: '0000',
    accId:'',
    resourceId:'',})
  }
  
  handleSelectCloudSpace = (event, data) => {
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState)=>({...prevState, [data.name]: data.value,
      openModal: false,
      ruleId:"",
      accId: "",
      resourceId: "",
      optionlistdata:[],
      acc_options:[]
    }),()=>{
      this.readDataFromApi();
      this.readDataAccountIdFromApi();
    });
  };

  handleCloseModal = ()=>{
    this.setState((prevState)=>({...prevState,openModal: false}),()=>{
      window.location.href = "/"
    });
  }

  componentDidMount(){
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
  }

  render() {
    let searchresult;
    let editformpopup;
    let deleteformpopup;
    const {searchResultData} = this.state
    if (this.state.searchStatus === 200 && this.state.ruleId != '0000' && searchResultData.length>0){
      //console.log('Searching the result !!!!! and displaying ')
      searchresult = <SortableTable {...this.state} handleEdit={this.handleEdit} handleDelete={this.handleDelete} headers={this.state.headers}/>
    }
    if (this.state.toEdit ==='yes'){
      editformpopup = <ResourceExceptionEditFormContainer {...this.state} dataEditedLoading={this.dataEditedLoading} handleEditPopUpClose={this.handleEditPopUpClose} accessData={this.props}/>
    }
    if (this.state.toDelete==='yes'){
      deleteformpopup = <ResourceExceptionDelete {...this.state} handleDeletePopUpClose={this.handleDeletePopUpClose} handleDeleteConfirm={this.handleDeleteConfirm} accessData={this.props}/>
      // delete module goes here 
    }
    return (
        <div>
          <ResourceExceptionSearchForm handleSubmit = {this.handleSubmit} handleDropDownChange = {this.handleDropDownChange} 
          {...this.state} readDataFromApi={this.readDataFromApi} handleChange={this.handleChange}
           dismissToastMessage={this.dismissToastMessage} readDataAccountIdFromApi={this.readDataAccountIdFromApi}
           handleCloseModal={this.handleCloseModal}
          handleSelectCloudSpace={this.handleSelectCloudSpace}
           />
          {searchresult}
          {editformpopup}
          {deleteformpopup}
        </div>
    )
  }
}

export default ResourceExceptionSearchContainer;
