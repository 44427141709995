export const commercialCloud = "aws"
export const usGovCloud = "aws-us-gov"

export const labelAws = {label:'Commercial Cloud',value:"aws"}
export const labelAwsUsGov = {label:"Gov Cloud",value:"aws-us-gov"}

export const cloudCompatibilityOption = [
    {key:0,text:"Commercial Cloud",value:"aws"},
    {key:1,text:"Gov Cloud",value:"aws-us-gov"}
]

export const metadataTableKeys = {
    iamPartner:"IamTrustedPartner",
    s3Partner:"S3TrustedPartner",
    iamGlobalPartner:"IamGlobalTrustedPartners",
    s3GlobalPartner:"S3GlobalTrustedPartners",
    s3WhiteList:"S3WhiteListedTrustedPartner",
    iamWhiteList:"IamWhiteListedTrustedPartner",
    s3BlackList:"S3BlackListedTrustedPartner",
    iamBlackList:"IamBlackListedTrustedPartner"
}

export const localStorageCloudValue="selectedOrg";

export const  convertToTitleCase = (text) =>{
    if (!text) return text;
    return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
}