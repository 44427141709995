import React, { useEffect, useState } from 'react'
import { Form, Radio, Icon, Popup, Header, Button, Label, Input, Grid, Message, Dimmer, Loader, Modal, Segment } from 'semantic-ui-react'
import { getMetadataLists, createTrustedVendor, getAccountDetail, getMetadataDetailsFromKey, getAccountList, updateServiceNowTable } from '../service'
import axios from 'axios'
import config from '../../../../../Config/Config'
import { commercialCloud, convertToTitleCase } from '../../../../../Config/appConstant'

export default function AddVendor(props) {
    
    const [value, setValue] = useState({ vendorName: "", error: false, message: "" })
    const [vendorIds, setVendorIds] = useState([{ id: 0, value: '', error: false, message: "" }])
    const [metadata, setMetadata] = useState({
        isLoading: false,
        error: null,
        data: null,
        sortKey: 0,
        listOfTrustedVendor: []
    })
    const [searchedResult, setSearchedResult] = useState({
        data: [],
        isLoading: false,
        message: "",
        openMessage: false,
        error: ""
    })
    const [whiteListTrustedVendor, setWhiteListTrustedVendor] = useState({
        data: [],
        isLoading: false,
        message: "",
        error: ""
    })
    const [blackListTrustedVendor, setBlackListTrustedVendor] = useState({
        data: [],
        isLoading: false,
        message: "",
        error: ""
    })
    const [accountDetail, setAccountDetail] = useState({
        data: [],
        isLoading: false,
        message: "",
        error: ""
    })
    const [accountLists, setAccountLists] = useState({
        data: [],
        isLoading: false,
        message: "",
        error: ""
    })

    const handleChangeVendorName = (e, data) => {
        const newState = { ...value }
        const newAccountIds = [...vendorIds]
        newState.vendorName = data?.value
        newState.error = false

        const findVendorRecord = metadata.data?.filter((item) => item?.GroupKey?.toLowerCase() === data.value?.toLowerCase())
        if (findVendorRecord?.length > 0) {
            newState.vendorName = findVendorRecord[0]?.GroupKey
        }
        else {
            newState.vendorName = data?.value
        }

        if (newState.vendorName.length < 3) {
            newState.error = true
            newState.message = "Length must be >= 3."
            vendorIds.map((vendorItem, index) => {
                newAccountIds[index].error = false
                newAccountIds[index].message = ""
            })
        }
        else if (vendorIds.length > 0) {

            vendorIds.map((vendorItem, index) => {
                if (findVendorRecord?.some((item) => item?.MetadataValue === vendorItem.value)) {
                    newAccountIds[index].error = true
                    newAccountIds[index].message = "Vendor account already exists!"
                }
                else if (whiteListTrustedVendor?.data?.some(item => parseInt(item) === parseInt(vendorItem.value))) {
                    newAccountIds[index].error = false
                    newAccountIds[index].message = ""
                }
                else if (blackListTrustedVendor?.data?.some(item => parseInt(item) === parseInt(vendorItem.value))) {
                    newAccountIds[index].error = true
                    newAccountIds[index].message = "Vendor account is not approved!"
                }
                else if (accountLists?.data?.some((item) => parseInt(item?.value) === parseInt(vendorItem?.value))) {
                    newAccountIds[index].error = true
                    newAccountIds[index].message = "Please enter a valid trusted vendor account!"
                }
                else {
                    newAccountIds[index].error = false
                    newAccountIds[index].message = ""
                }
            })

        }
        setValue(newState)
    }

    const fetchMetadataLists = (token) => {
        setMetadata((preData) => ({ ...preData, isLoading: true }))
        let getVendorType = props.vendorType === "LOCAL" ? "TrustedVendor" : "GlobalTrustedVendors"
        getMetadataLists(token,getVendorType,props?.selectCloud).then((response) => {
            
            if (response?.data?.data.length > 0) {
                let listOfTrustedVendor = []
                let trustedVendorUniqueName = new Set()
                let sortKeys = []
                response?.data?.data?.map((item, index) => {
                    trustedVendorUniqueName.add(item?.GroupKey)
                    sortKeys.push(parseInt(item.SortKey))
                })

                trustedVendorUniqueName.forEach((item) => {
                    listOfTrustedVendor.push({
                        key: item,
                        text: item,
                        value: item
                    })
                })
                sortKeys.sort((a, b) => b - a)
                setMetadata((preData) => ({
                    ...preData, isLoading: false,
                    data: response?.data?.data,
                    listOfTrustedVendor: listOfTrustedVendor,
                    sortKey: sortKeys[0] + 1
                }))
            }
            else {
                setMetadata((preData) => ({
                    ...preData, isLoading: false,
                    sortKey: props.vendorType === "LOCAL" ? 1000 : 2000
                }))
            }

        }).catch(error => {
            if(error?.response?.status === 404){
                setMetadata((preData) => ({
                    ...preData,
                    isLoading: false,
                    data: null,
                    error: error.message,
                    listOfTrustedVendor: [],
                    sortKey: props.vendorType === "LOCAL" ? 1000 : 2000
                }))
            }
            else{
                setMetadata((preData) => ({
                    ...preData,
                    isLoading: false,
                    data: null,
                    error: error.message,
                    listOfTrustedVendor: []
                }))
            }
            
            console.error(error)
        })
    }
    useEffect(() => {
        let token = props.authState.accessToken.accessToken
        if (token) {
            fetchMetadataLists(token)
            fetchMetadataDetailsFromKey(token)
            fetchAccountList(token)
        }
    }, [props?.selectCloud, props?.vendorType])
    const handleAddMoreInputFields = () => {
        const newInputFields = [...vendorIds]
        newInputFields.push({ id: Date.now(), value: "" })
        setVendorIds(newInputFields)
    }
    const handleRemoveInputFields = (index) => {
        const newInputFields = vendorIds.filter((_, i) => i !== index);
        setVendorIds(newInputFields)
    }
    const handleRemoveMessageBox = () => {
        setSearchedResult((prevState) => ({ ...prevState, openMessage: false, error: "", message: "" }))
    }
    
    const handleSubmit = () => {
        setSearchedResult((preState) => ({ ...preState, isLoading: true, error: "", data: [], openMessage: false, message: "" }))
        let token = props.authState.accessToken.accessToken
        let payload = vendorIds.map((item, index) => {
            return ({
                MetadataKey: props?.vendorType === "LOCAL" ?  "TrustedVendor" : "GlobalTrustedVendors",
                SortKey: metadata?.sortKey + index,
                GroupKey: value.vendorName,
                LastModifiedBy: props?.authState?.accessToken?.claims?.sub,
                MetadataType: "Portal",
                MetadataValue: item.value,
                Associations: [],
                RuleList: []
            })
        })
        
        let snow_payload =  {
            eventType:"TRUSTED_VENDOR",
            data:{
                records:payload?.map((_v)=>({
                    "u_trustedvendorname": _v?.GroupKey, 
                    "u_trustedvendorid": _v?.MetadataValue, 
                    "u_trustedvendortype": convertToTitleCase(props?.vendorType), 
                    "u_associatedaccounts": "" 
                }))
            }
        }
        
        createTrustedVendor(token, payload, props?.selectCloud).then(async result => {
            if (result.status === 201) {
                if(props?.selectCloud === commercialCloud){
                    try{
                        await updateServiceNowTable(snow_payload,token, props?.selectCloud)
                        setSearchedResult((preState) => ({
                            ...preState,
                            isLoading: false,
                            error: "",
                            data: result,
                            message: "Record created successfully!",
                            openMessage: true
                        }))
                    }
                    catch(err){
                        console.error(err)
                        setSearchedResult((preState) => ({
                            ...preState,
                            isLoading: false,
                            error: `Failed to created record in ServiceNow table!`,
                            data: result,
                            message:"",
                            openMessage: true
                        }))
                    }
                }
                else{
                    setSearchedResult((preState) => ({
                        ...preState,
                        isLoading: false,
                        error: "",
                        data: result,
                        message: "Record created successfully!",
                        openMessage: true
                    }))
                }
                
                setVendorIds([{ id: 0, value: '' }])
                setValue({ vendorName: "" })
                fetchMetadataLists(token)
                setTimeout(() => { handleRemoveMessageBox() }, 3000)
            }
            else {
                setSearchedResult((preState) => ({
                    ...preState,
                    isLoading: false,
                    error: "Failed to create record!",
                    data: result,
                    message: "",
                    openMessage: true
                }))
            }
        }).catch(err => {
            setSearchedResult((preState) => ({
                ...preState,
                isLoading: false,
                error: err.message,
                data: [],
                message: "",
                openMessage: true
            }))
            console.error(err)
        })
    }

    const fetchMetadataDetailsFromKey = (token) => {
        getMetadataDetailsFromKey(token, "BlacklistedTrustedVendors",props?.selectCloud).then((response) => {
            const result = response?.data?.body?.data
            if(result?.length>0){
                
                setBlackListTrustedVendor({ data: result[0]?.Metadata?.BlacklistedTrustedVendors?.TrustedVendors[0]?.MetadataValue })
            }
            
            getMetadataDetailsFromKey(token, "WhitelistedTrustedVendors",props?.selectCloud).then((response) => {
                const reponseData = response?.data?.body?.data
                setWhiteListTrustedVendor({ data: reponseData[0]?.Metadata?.WhitelistedTrustedVendors?.TrustedVendors[0]?.MetadataValue })
            }).catch(err => {
                console.error(err)
            })
        }).catch(err => {
            console.error(err)
        })
    }
    const fetchAccountList = (token) => {
        getAccountList(token,props?.selectCloud).then((res) => {
            setAccountLists({ data: res.data?.body?.data })
        }).catch(err => console.error(err))
    }

    const { vendorName } = value

    return (
        <React.Fragment>
            <div>
                <Dimmer active={searchedResult.isLoading || accountDetail.isLoading || metadata?.isLoading} inverted>
                    <Loader />
                </Dimmer>
            </div>
            <div style={{ width: "100%", top: 0, position: "absolute", left: 0 }}>
                <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }} >
                    {searchedResult.openMessage ? <Message size="large" style={{ width: "40%" }} onDismiss={handleRemoveMessageBox}
                        content={searchedResult.message === "" ? searchedResult.error : searchedResult.message}
                        color={searchedResult.message === "" ? "red" : "green"} /> : null}
                </div>
            </div>
            <fieldset style={{marginTop:40, border:"1px solid #cfcfcf"}}>
            <legend style={{padding:"5px 10px", backgroundColor:"rgb(42, 125, 225)", color:"#fff"}}>Add Trusted Vendor</legend>
   
            <div style={{ marginTop: "30px",padding:10 }}>
                <Grid columns={3} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <div><label style={{ fontWeight: 700, fontSize: "12px" }}>Enter Vendor Name<span style={{ color: '#db2828' }}>*</span></label></div>
                            <Input
                                name="vendorName"
                                placeholder="e.g., Databricks (Vendor Name)"
                                style={{ minWidth: "90%" }}
                                value={vendorName}
                                onChange={handleChangeVendorName}
                                error={value.error}
                            />
                            {value.error ? (<div className="ui basic" style={{ color: "#db2828", fontSize: "10px" }}>{value.message}</div>) : null}
                        </Grid.Column>
                        <Grid.Column>
                            <div><label style={{ fontWeight: 700, fontSize: "12px" }}>
                                Enter Vendor Account or AMI source pattern: <span style={{ color: '#db2828' }}>*</span></label></div>
                            {vendorIds?.map((item, index) => (
                                <div key={index}>
                                    <div style={{ display: "flex" }}>
                                        <Input
                                            name={`vendorId-${item.id}`}
                                            placeholder="e.g., 601306020600 or (601306020600/databricks-worker or amazon/amzn-ec2)"
                                            value={item.value}
                                            style={{ minWidth: "90%", marginBottom: "10px" }}
                                            onChange={(e, data) => {
                                                if (!isNaN(data?.value) ) {
                                                    if(data?.value.length <= 12){
                                                        const newInputFields = [...vendorIds]
                                                        newInputFields[index].value = data?.value
                                                        newInputFields[index].error = false
                                                        newInputFields[index].message = ""
    
                                                        let token = props.authState.accessToken.accessToken
                                                        if(data.value===""){
                                                            newInputFields[index].error = false
                                                            newInputFields[index].message = ""
                                                        }
                                                        else if (data?.value?.length < 12) {
                                                            newInputFields[index].error = true
                                                            newInputFields[index].message = "Length must be equal to 12."
                                                        }
                                                        else if(vendorIds?.filter((item)=>(item?.value?.toLowerCase() === data.value?.toLowerCase()))?.length>1){
                                                            newInputFields[index].error = true
                                                            newInputFields[index].message = "Vendor Account Already Entered!"
                                                        }
                                                        else if (metadata.data?.some((item) => (item?.MetadataValue === data.value)
                                                            && (item?.GroupKey?.toLowerCase() === value.vendorName?.toLowerCase()))) {
                                                            newInputFields[index].error = true
                                                            newInputFields[index].message = "Vendor Account Already Exists!"
                                                        }
                                                        else if (data?.value?.length === 12) {
    
                                                            if (whiteListTrustedVendor?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                                                                newInputFields[index].error = false
                                                            }
                                                            else if (blackListTrustedVendor?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                                                                newInputFields[index].error = true
                                                                newInputFields[index].message = "Vendor account is not approved!"
                                                            }
                                                            else {
                                                                setAccountDetail({ data: [], isLoading: true })
                                                                //To check account is blacklisted or not.
                                                                let url = commercialCloud === props?.selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
                                                                axios.get(`${url}query?Table=${'ACCOUNTS_TABLE'}&Key=${'AccountId'}&Value=${data?.value}`,
                                                                    {
                                                                        headers: {
                                                                            'Authorization': token
                                                                        }
                                                                    }).then((res => {
    
                                                                        if (res.status === 200) {
                                                                            newInputFields[index].error = true
                                                                            newInputFields[index].message = "Please enter a valid trusted vendor account!"
                                                                            setAccountDetail({ data: res.data, isLoading: false })
                                                                        }
                                                                        else {
                                                                            setAccountDetail({ data: [], isLoading: false })
                                                                        }
    
                                                                    })).catch(error => {
                                                                        setAccountDetail({ data: [], isLoading: false })
                                                                    })
                                                            }
                                                        }
                                                        setVendorIds(newInputFields)
                                                    }
                                                }
                                                else {
                                                    let patternMatcher = /^[^\/]+\/[^\/]+$/;
                                                    const newInputFields = [...vendorIds]
                                                    newInputFields[index].value = data?.value
                                                    newInputFields[index].error = false
                                                    newInputFields[index].message = ""

                                                    if (metadata.data?.some((item) => (item?.MetadataValue === data.value)
                                                        && (item?.GroupKey?.toLowerCase() === value.vendorName?.toLowerCase()))) {
                                                            newInputFields[index].error = true
                                                            newInputFields[index].message = "AMI source pattern already exists!"
                                                    }
                                                    else if(vendorIds?.filter((item)=>(item?.value?.toLowerCase() === data.value?.toLowerCase()))?.length>1){
                                                        newInputFields[index].error = true
                                                        newInputFields[index].message = "AMI source pattern already entered!"
                                                    }
                                                    else if(!patternMatcher.test(data?.value)){
                                                        newInputFields[index].error = true
                                                        newInputFields[index].message = "AMI source pattern is not valid!"
                                                        
                                                    }
                                                    setVendorIds(newInputFields)
                                                    
                                                }
                                            }}
                                            error={item.error}
                                        />
                                        {vendorIds.length > 1 ?
                                            (<Icon style={{ padding: "5px 10px 0px 10px", cursor: "pointer" }} onClick={() => handleRemoveInputFields(index)} name="delete" />
                                            ) : null}
                                    </div>
                                    {item.error ? (<div className="ui basic"
                                        style={{ color: "#db2828", marginBottom: "7px", marginTop: "-7px", fontSize: "10px" }}>
                                        {item.message}</div>) :
                                        null}
                                </div>
                            ))}
                            <div style={{ width: "90%", display: "flex", flexDirection: "row-reverse" }}>
                                <Button
                                    disabled={((vendorIds[vendorIds?.length - 1].value?.length >= 12) && (!vendorIds[vendorIds?.length - 1].error)) ? false : true}
                                    onClick={handleAddMoreInputFields}
                                    style={{
                                        fontSize: "x-small",
                                        color: "#2A7DE1",
                                        backgroundColor: "#fff",
                                        padding: "0px",
                                        marginTop: "15px"
                                    }}>Add more</Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button content='Reset' onClick={() => {
                                setVendorIds([{ id: 0, value: '' }])
                                setValue({ vendorName: "" })
                            }} style={{ width: "100px", fontSize: "small" }} />
                            <Button onClick={handleSubmit} content='Submit' style={{ width: "100px", fontSize: "small", backgroundColor: "#2A7DE1", color: "#fff" }}
                                disabled={(vendorIds.some(item => item.error) || value.error) ||
                                 (value.vendorName.length < 2)|| vendorIds.some((item)=>item.value==="") || accountDetail.error} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            </fieldset>
        </React.Fragment>
    )
}
