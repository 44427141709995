import React, { useState } from 'react';
import { Form, Button, Dropdown, Grid, Container, Header, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { handleRootAccess } from './service';
import { ErrorModal, SuccessModal } from './CustomMessageModal';
import { commercialCloud } from '../../../../Config/appConstant';

const hoursOptions = [
  {
    key: 1,
    text: '1 hours',
    value: 3600
  },
  {
    key: 2,
    text: '3 hours',
    value: 10800
  },
  {
    key: 3,
    text: '6 hours',
    value: 21600
  },
  {
    key: 4,
    text: '12 hours',
    value: 43200
  },
  {
    key: 5,
    text: '24 hours',
    value: 86400
  }
]


const validationSchema = Yup.object().shape({
  accountName: Yup.string()
    .required('Account selection is required'),
  privilegeAccessDuration: Yup.number()
    .required('Access duration is required'),
  serviceNowId: Yup.string()
    .required('ServiceNow ID is required')
    .min(11, "ServiceNow ID must be exactly 11 letters long")
    .max(11, "ServiceNow ID must be exactly 11 letters long")
    .matches(/^[a-zA-Z0-9,-]*$/, "ServiceNow ID should not contain spaces")
    .test(
      "RITM",
      "ServiceNow ID should start with RITM",
      val => val && val.startsWith("RITM")
    )
    .test(
      "RITMNUM",
      "ServiceNow ID should contain digits after RITM",
      val => val && !isNaN(val.substring(4))
    )
});

const styles = {
  headerSegment: {
    backgroundColor: '#f7f7f7',
    padding: '1rem',
    width: "90%",
    marginLeft: "5%",
    marginBottom: '2rem',
    border: 'none',
    boxShadow: 'none',
  },
  formContainer: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    borderRadius: '4px',
  },
  input: {
    backgroundColor: '#f7f7f7',
  },
  dropdown: {
    backgroundColor: '#f7f7f7 !important',
  },
  submitButton: {
    marginTop: '2rem',
  }
};

const PrivilegeAccessForm = (props) => {

  const [result,setResult] = useState(null)
  const [loading,setLoading] = useState(false)

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const initialValues = {
    accountName: '',
    privilegeAccessDuration: '',
    serviceNowId: '',
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setLoading(true)
    let findAccount = props?.accountOptions?.data?.find((item) => item?.value == values?.accountName);
    let payload = {
      "AccountId": values?.accountName,
      "AccountName": findAccount?.accountName,
      "PrivilegeAccessDuration": values?.privilegeAccessDuration,
      "CreatedBy": props?.username?.login,
      "EventTime": Date.now(),
      "Source": "cem-portal",
      "ServiceNowTicketId":values?.serviceNowId
    }
    handleRootAccess(props?.selectCloud, props.authState.accessToken.accessToken, payload).then((res) => {
      setSubmitting(false);
      setLoading(false)
      setResult(res?.data)
      setSuccessOpen(true)
      setModalMessage(res?.data?.message)
      resetForm()
    }).catch((err) => {
      setSubmitting(false);
      setLoading(false)
      setResult(err?.response?.data)
      setErrorOpen(true)
      setModalMessage(err?.response?.data?.message)
    })
  };

  return (
    <Container>
      <Dimmer active={props?.accountOptions?.isLoading || loading} inverted>
          <Loader>Please wait...</Loader>
      </Dimmer>
      <Grid centered>
        <Grid.Column mobile={16} tablet={12} computer={8}>
          <Segment basic style={styles.headerSegment}>
            <Header as="h2" color='blue' textAlign="center">
              Enable Root Access
            </Header>
            {!(props?.selectCloud === commercialCloud) ? (<div style={{width:"100%",display:"flex",justifyContent:"center"}}><span style={{color:"red"}}>Root enable access is not available in GovCloud!</span></div> ) : null}
          </Segment>
          <SuccessModal
            open={successOpen}
            onClose={() => setSuccessOpen(false)}
            message={modalMessage}
          />
          <ErrorModal 
            open={errorOpen}
            onClose={() => setErrorOpen(false)}
            message={modalMessage}
          />
          <div style={styles.formContainer}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form size="large" onSubmit={handleSubmit}>
                  <Form.Field
                    error={touched.accountName && errors.accountName}
                    className="field"
                  >
                    <label>Account Name</label>
                    <Dropdown
                      id="cram-account-name"
                      placeholder="Select Account"
                      fluid
                      search
                      selection
                      options={props?.accountOptions?.data?.map((item) => ({ key: item?.key, text: item?.text, value: item?.value }))}
                      value={values.accountName}
                      onChange={(_, { value }) => setFieldValue('accountName', value)}
                      onBlur={handleBlur}
                      name="accountName"
                      style={styles.dropdown}
                      searchInput={{ name: 'account-search' }}
                      loading={props?.accountOptions?.isLoading}
                    />
                    {touched.accountName && errors.accountName && (
                      <div className="ui pointing red basic label">
                        {errors.accountName}
                      </div>
                    )}
                  </Form.Field>

                  <Form.Field
                    error={touched.privilegeAccessDuration && errors.privilegeAccessDuration}
                    className="field"
                  >
                    <label>Privilege Access Duration</label>
                    <Dropdown
                      id="cram-access-duration"
                      placeholder="Select Duration"
                      fluid
                      selection
                      options={hoursOptions}
                      value={values.privilegeAccessDuration}
                      onChange={(_, { value }) => setFieldValue('privilegeAccessDuration', value)}
                      onBlur={handleBlur}
                      name="privilegeAccessDuration"
                      style={styles.dropdown}

                    />
                    {touched.privilegeAccessDuration && errors.privilegeAccessDuration && (
                      <div className="ui pointing red basic label">
                        {errors.privilegeAccessDuration}
                      </div>
                    )}
                  </Form.Field>

                  <Form.Field
                    error={touched.serviceNowId && errors.serviceNowId}
                    className="field"
                  >
                    <label>ServiceNow ID</label>
                    <input
                      id="cram-service-now-id"
                      placeholder="Enter ServiceNow ID"
                      type="text"
                      name="serviceNowId"
                      value={values.serviceNowId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={styles.input}
                    />
                    {touched.serviceNowId && errors.serviceNowId && (
                      <div className="ui pointing red basic label">
                        {errors.serviceNowId}
                      </div>
                    )}
                  </Form.Field>

                  <Button.Group fluid style={styles.submitButton}>
                    <Button
                      type="button"
                      size="large"
                      onClick={() => {
                        const form = values;
                        Object.keys(form).forEach(key => {
                          setFieldValue(key, initialValues[key]);
                        });
                      }}
                      style={{ marginRight: '10px' }}
                    >
                      Reset
                    </Button>
                    {
                      (props?.selectCloud === commercialCloud) ? (
                        <Button
                      type="submit"
                      primary
                      size="large"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                      ) : (<Button
                        primary
                        size="large"
                        disabled={true}
                      >
                        Submit
                      </Button>)
                    }
                  </Button.Group>
                </Form>
              )}
            </Formik>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default PrivilegeAccessForm;
