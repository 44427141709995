import { metadataTableKeys } from "../../../Config/appConstant"

export const dataPerPageOptions = [
    { key: '1', text: '5', value: 5 },
    { key: '2', text: '10', value: 10 },
    { key: '3', text: '20', value: 20 },
    { key: '4', text: '30', value: 30 },
    { key: '5', text: '50', value: 50 },
    { key: '6', text: '100', value: 100 },
    { key: '7', text: '200', value: 200 },
    { key: '8', text: '300', value: 300 },
    { key: '9', text: '400', value: 400 },
    { key: '10', text: '500', value: 500 }
]

export const VendorType = [
    { key: '1', text: 'Local', value: "TrustedVendor" },
    { key: '2', text: 'Global', value: "GlobalTrustedVendors" },
]

export const PartnerType = {
    "iam": [
        { key: '1', text: 'IAM Local', value: metadataTableKeys.iamPartner },
        { key: '2', text: 'IAM Global', value: metadataTableKeys.iamGlobalPartner }
    ],
    "s3": [
        { key: '3', text: 'S3 Local', value: metadataTableKeys.s3Partner },
        { key: '4', text: 'S3 Global', value: metadataTableKeys.s3GlobalPartner }
    ]
}

export const PartnerRuleNames = [
    { key: '1', text: `${process.env.REACT_APP_RUN_ENVIRONMENT === "PROD" ? 'VA' : process.env.REACT_APP_RUN_ENVIRONMENT}-iam-role-external-perms`, value: "iam-role-external-perms" },
    { key: '2', text: `${process.env.REACT_APP_RUN_ENVIRONMENT === "PROD" ? 'VA' : process.env.REACT_APP_RUN_ENVIRONMENT}-s3-external-perms`, value: "s3-external-perms" }
]

export const VendorRuleName = `${process.env.REACT_APP_RUN_ENVIRONMENT === "PROD" ? 'VA' : process.env.REACT_APP_RUN_ENVIRONMENT}-ec2-rogue-ami`