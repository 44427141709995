import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { localStorageCloudValue, metadataTableKeys } from '../../../../Config/appConstant'
import { Checkbox, Grid, Icon, Pagination, Popup, Segment, Table, Label, Button, Input, Dropdown, Image, Header, Dimmer, Loader } from 'semantic-ui-react'
import Papa from 'papaparse'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import CloudModal from '../CloudModal'

import { getAccountLists, getMetadataLists } from './service'
import { dataPerPageOptions } from '../appConstant'
import csvIcon from '../../../../Assets/Images/csv_icon.png'
import FilterBox from './FilterBox'
const initalState = {
  localIamPartner: { data: [], isLoading: false },
  globalIamPartner: { data: [], isLoading: false },
  localS3Partner: { data: [], isLoading: false },
  globalS3Partner: { data: [], isLoading: false }
}

const actionType = {
  SET_LOCAL_IAM_PARTNER: "SET_LOCAL_IAM_PARTNER",
  SET_GLOBAL_IAM_PARTNER: "SET_GLOBAL_IAM_PARTNER",
  SET_LOCAL_S3_PARTNER: "SET_LOCAL_S3_PARTNER",
  SET_GLOBAL_S3_PARTNER: "SET_GLOBAL_S3_PARTNER",
}

function reducer(state, action) {
  switch (action.type) {
    case actionType.SET_LOCAL_IAM_PARTNER:
      return {
        ...state,
        localIamPartner: action.payload
      };
    case actionType.SET_GLOBAL_IAM_PARTNER:
      return {
        ...state,
        globalIamPartner: action.payload
      }
    case actionType.SET_LOCAL_S3_PARTNER:
      return {
        ...state,
        localS3Partner: action.payload
      };
    case actionType.SET_GLOBAL_S3_PARTNER:
      return {
        ...state,
        globalS3Partner: action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default function PartnerReport(props) {
  const [selectCloud, setSelectCloud] = useState("")
  const [openModal, setOpenModal] = useState(true)
  const [toggleTableView, setToggleTableView] = useState(true)
  const [metadataStore, dispatch] = useReducer(reducer, initalState)
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState(10)
  const [accounts, setAccounts] = useState([])
  const [search, setSearch] = useState("")
  const [selectedAccount, setSelectedAccount] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [copyMetadata, setCopyMetadata] = useState([])
  const [partnersOption, setPartnerOption] = useState([])
  const [partnerName, setPartnerName] = useState("")
  const [downloadMarkedAllData, setDownloadMarkedAllData] = useState(false)
  const [downloadMarkedDataRecord, setDownloadMarkedDataRecord] = useState([])
  const [storeCheckData, setStoreCheckData] = useState([])
  const [sortTableHeaderIndex, setSortTableHeaderIndex] = useState(-1)
  const [sortTable, setSortTable] = useState(false)
  const [ruleName,setRuleName] = useState("")
  const [pdfRuleName,setPdfRuleName] = useState("")
  useEffect(() => {
    let value = localStorage.getItem(localStorageCloudValue)
    if (value !== null && value !== "") {
      setSelectCloud(value)
      setOpenModal(false)
    }
  }, [])

  useEffect(() => {
    let token = props.authState.accessToken.accessToken
    const invokeMethods = () => {
      dispatch({ type: actionType.SET_LOCAL_IAM_PARTNER, payload: { data: [], isLoading: true } })
      dispatch({ type: actionType.SET_GLOBAL_S3_PARTNER, payload: { data: [], isLoading: true } })
      getAccountLists(token, selectCloud).then(response => {
        setAccounts(response?.data?.body?.data)
      }).catch(err => console.error(err))
      getMetadataLists(token, metadataTableKeys.iamPartner, selectCloud).then(localPartner => {
        if (localPartner.status === 200) {
          dispatch({ type: actionType.SET_LOCAL_IAM_PARTNER, payload: { data: localPartner?.data?.data, isLoading: false } })
        }
        else {
          dispatch({ type: actionType.SET_LOCAL_IAM_PARTNER, payload: { data: [], isLoading: false } })
        }
      })
        .catch(err => {
          console.error(err)
          dispatch({ type: actionType.SET_LOCAL_IAM_PARTNER, payload: { data: [], isLoading: false } })
        })
      getMetadataLists(token, metadataTableKeys.iamGlobalPartner, selectCloud).then(globalPartner => {
        if (globalPartner.status === 200) {
          dispatch({ type: actionType.SET_GLOBAL_IAM_PARTNER, payload: { data: globalPartner?.data?.data, isLoading: false } })
        }
        else {
          dispatch({ type: actionType.SET_GLOBAL_IAM_PARTNER, payload: { data: [], isLoading: false } })
        }
      })
        .catch(err => {
          console.error(err)
          dispatch({ type: actionType.SET_GLOBAL_IAM_PARTNER, payload: { data: [], isLoading: false } })
        })
      getMetadataLists(token, metadataTableKeys.s3Partner, selectCloud).then(localPartner => {
        if (localPartner.status === 200) {
          dispatch({ type: actionType.SET_LOCAL_S3_PARTNER, payload: { data: localPartner?.data?.data, isLoading: false } })
        }
        else {
          dispatch({ type: actionType.SET_LOCAL_S3_PARTNER, payload: { data: [], isLoading: false } })
        }
      })
        .catch(err => {
          console.error(err)
          dispatch({ type: actionType.SET_LOCAL_S3_PARTNER, payload: { data: [], isLoading: false } })
        })
      getMetadataLists(token, metadataTableKeys.s3GlobalPartner, selectCloud).then(globalPartner => {
        if (globalPartner.status === 200) {
          dispatch({ type: actionType.SET_GLOBAL_S3_PARTNER, payload: { data: globalPartner?.data?.data, isLoading: false } })
        }
        else {
          dispatch({ type: actionType.SET_GLOBAL_S3_PARTNER, payload: { data: [], isLoading: false } })
        }
      })
        .catch(err => {
          console.error(err)
          dispatch({ type: actionType.SET_GLOBAL_S3_PARTNER, payload: { data: [], isLoading: false } })
        })
    }
    if (token && selectCloud !== "") {
      invokeMethods()
    }
  }, [selectCloud])
  const handleFilters = () => {
    setStoreCheckData([])
    let tempStore = [...metadataStore?.localIamPartner?.data, ...metadataStore?.globalIamPartner?.data, ...metadataStore?.localS3Partner?.data, ...metadataStore?.globalS3Partner?.data]?.map((item) => {
      return {
        ...item,
        Associations: item?.Associations?.map((association) => {
          let getAccountDetails = accounts?.find(
            (buAccountDetails) => buAccountDetails?.value === association
          );
          return getAccountDetails?.accountName;
        }),
      };
    })
    let store = tempStore
    if (selectedAccount === "" && selectedType === "" && partnerName === "" && ruleName === "") {
      setCopyMetadata(store)
    }
    else {
      setCopyMetadata(
        store?.filter(item => {
          return Object.entries({
            RuleName:ruleName,
            MetadataKey: selectedType,
            GroupKey: partnerName,
            Associations: selectedAccount
          }).every(([key, values]) => {
            if (!values) return true;
            if (!Array.isArray(values)) {
              values = [values];
            }
            if(key === "RuleName"){
              let _type = ruleName?.toLowerCase()?.includes("iam") ? "Iam" : "S3"
              return values?.some(value=>item["MetadataKey"]?.includes(_type))
            }
            if (key === "Associations" && Array.isArray(item[key])) {
              return values.some(value => item[key].includes(value));
            }
            return values.includes(item[key]);
          });
        })
      )
    }
  }
  useEffect(() => {
    handleFilters()
    
  }, [selectedAccount, selectedType, partnerName,ruleName])
  const mergePartner = useMemo(() => {
    return [
      ...(metadataStore?.localIamPartner?.data || []),
      ...(metadataStore?.globalIamPartner?.data || []),
      ...(metadataStore?.localS3Partner?.data || []),
      ...(metadataStore?.globalS3Partner?.data || []),
    ]?.map((item) => {
      return {
        ...item,
        Associations: item?.Associations?.map((association) => {
          let getAccountDetails = accounts?.find(
            (buAccountDetails) => buAccountDetails?.value === association
          );
          return getAccountDetails?.accountName;
        }),
      };
    });
  }, [metadataStore, accounts]);
  useEffect(() => {
    setCopyMetadata(mergePartner)
    handlePartnerName()
  }, [mergePartner])

  const handlePartnerName = () => {
    let trustedPartnerUniqueName = new Set();
    let setOption = [];
    [...metadataStore?.localIamPartner?.data, ...metadataStore?.globalIamPartner?.data, ...metadataStore?.localS3Partner?.data, ...metadataStore?.globalS3Partner?.data]?.map((item, index) => {
      trustedPartnerUniqueName.add(item?.GroupKey)
    });
    trustedPartnerUniqueName.forEach((item) => {
      setOption.push({
        key: item,
        text: item,
        value: item
      })
    })
    setPartnerOption(setOption)
  }

  const handleDownloadCsv = (data, fileName) => {
    if (!data && !fileName) return
    const downloadData = data.map((item) => {
      return {
        TrustedPartnerName: item?.GroupKey,
        TrustedPartnerId: item?.MetadataValue,
        TrustedPartnerType: item?.MetadataKey?.toLowerCase()?.includes("global") ? (item?.MetadataKey?.toLowerCase()?.includes("iamglobal") ? 'IAM Global' : 'S3 Global')
        : item?.MetadataKey?.toLowerCase()?.includes("iamtrus") ? 'IAM Local' : 'S3 Local'|| "",
        Associations: item?.Associations?.map((association) => {
          let getAccountDetails = accounts?.find(
            (buAccountDetails) => buAccountDetails?.accountName === association
          );
          return getAccountDetails?.value;
        })
      }
    })
    const csv = Papa.unparse(downloadData);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${fileName}_${Date.now()}.csv`);
    tempLink.click();
  }

  const handleDownloadPdf = (body, header, fileName) => {
    if (!body || !header || !fileName) return;

    const pdf = new jsPDF("p", "mm", "a4"); // A4 size, portrait mode
    const now = new Date();
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const orderedHeader = header?.map(colName => {
      switch (colName) {
        case "GroupKey":
          return "Trusted Partner Name";
        case "MetadataKey":
          return "Trusted Partner ID";
        case "MetadataValue":
          return "Trusted Partner Type";
        case "Associations":
          return "Associations(Verisk Accounts)";
        default:
          return colName;
      }
    });
    const orderedBody = body?.map(item => [
      item?.GroupKey || "",
      item?.MetadataValue || "",
      item?.MetadataKey?.toLowerCase()?.includes("global") ? (item?.MetadataKey?.toLowerCase()?.includes("iamglobal") ? 'IAM Global' : 'S3 Global')
      : item?.MetadataKey?.toLowerCase()?.includes("iamtrus") ? 'IAM Local' : 'S3 Local'|| "",
      (item?.Associations || [])
        .map(association =>
          accounts?.find(
            account => account?.accountName?.toLowerCase() === association?.toLowerCase()
          )?.value || association
        )
        .join(", ")
    ]);
    pdf.setFontSize(12);
    pdf.text(`Report for the Trusted Partner on ${pdfRuleName}.`, 105, 15, { align: "center" });
    autoTable(pdf, {
      head: [orderedHeader],
      body: orderedBody,
      theme: "grid",
      styles: { fontSize: 8, cellPadding: 3, overflow: "linebreak" },
      startY: 25,
      margin: { top: 10, left: 10, right: 10 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
        3: { cellWidth: 50 },
      },
      pageBreak: "auto",
      didDrawPage: data => {
        const pageSize = pdf.internal.pageSize;
        const pageHeight = pageSize.height || pageSize.getHeight();
        const footerY = pageHeight - 8;

        pdf.setFontSize(9);
        pdf.text(
          `Report generated on ${now.toLocaleString("en-US", options)}`,
          10,
          footerY
        );
        pdf.text(`Page ${data.pageNumber}`, pageSize.width - 20, footerY, {
          align: "right",
        });
      },
    });

    // Save the file
    pdf.save(`${fileName}.pdf`);
  };
  const handleSortTable = (value, index) => {
    setSortTableHeaderIndex(index)
    setSortTable(!sortTable)
    setCurrentPage(1)
    setCopyMetadata(copyMetadata?.sort((a, b) => {
      if (sortTable) {
        if (a[value] < b[value]) return -1;
        if (a[value] > b[value]) return 1;
      }
      else {
        if (a[value] > b[value]) return -1;
        if (a[value] < b[value]) return 1;
      }
      return 0;
    }))
  }
  const tableHeader = ["GroupKey", "MetadataKey", "MetadataValue", "Associations"]
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const _currentData = copyMetadata
    ?.filter(item => {
      return tableHeader?.some(key => {
        return JSON.stringify(item[key])?.toLowerCase().includes(search?.trim().toLowerCase())
      })
    })?.map((item, index) => {
      return {
        ...item,
        index: index
      }
    })
    ?.sort((a, b) => a?.GroupKey?.localeCompare(b?.GroupKey));
  const currentData = _currentData?.slice(indexOfFirstData, indexOfLastData)
  let tableHeaderCell = [
    { text: "Trusted Partner Name", value: "GroupKey" },
    { text: "Trusted Partner Account ID", value: "MetadataValue" },
    { text: "Internal Accounts (Associations)", value: "Associations" },
    { text: "Trusted Partner Type", value: "MetadataKey" }]
  const RenderTableView = () => (
    <React.Fragment>
      <div style={{ width: "100%", overflow: 'scroll', maxHeight: "65vh", marginTop: "10px" }}>
        <Table celled striped sortable>
          <Table.Header style={{ position: "sticky", top: 0, backgroundColor:"#fff"}} >
            <Table.Row>
              <Table.HeaderCell>
                <Popup
                  content={downloadMarkedAllData ? "Unmarked all" : "Marked all for download!"}
                  trigger={
                    <Checkbox checked={downloadMarkedDataRecord?.includes(currentPage)} onChange={(e, data) => {
                      if (data.checked) {
                        let filterData = currentData?.filter((item) => !storeCheckData?.some((value) => value.index === item.index))
                        setStoreCheckData(storeCheckData.concat(filterData))
                        setDownloadMarkedAllData(!downloadMarkedAllData)
                        setDownloadMarkedDataRecord([...downloadMarkedDataRecord, currentPage])
                      }
                      else {
                        setStoreCheckData(storeCheckData?.filter((item) => {
                          return !currentData.some((res) => res.index === item.index)
                        }))
                        setDownloadMarkedAllData(!downloadMarkedAllData)
                        setDownloadMarkedDataRecord(downloadMarkedDataRecord?.filter((item) => item !== currentPage))
                      }
                    }} />
                  } />
              </Table.HeaderCell>
              {
                (!selectedType?.toLowerCase()?.includes("global") ?
                  tableHeaderCell
                  : tableHeaderCell?.filter((_item) => _item?.value !== "Associations"))?.map((item, index) => {
                    return (<Table.HeaderCell key={index} 
                      sorted={sortTable && index === sortTableHeaderIndex ? 'ascending' : 'descending'}
                      onClick={() => { handleSortTable(item.value, index) }}>
                      {item.text}
                    </Table.HeaderCell>)
                  })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              currentData && currentData.length > 0 ? currentData.map((item, index) => (
                <Table.Row key={index} >
                  <Table.Cell>
                    <Checkbox
                      checked={storeCheckData?.some((data) => data?.index === item?.index)}
                      onChange={(e, data) => {
                        if (data?.checked) {
                          if (!storeCheckData?.some(_item => _item?.index === item?.index)) {
                            setStoreCheckData([...storeCheckData, ...[item]])
                          }
                        }
                        else {
                          if (storeCheckData?.some(_item => _item?.index === item?.index)) {
                            setStoreCheckData(storeCheckData?.filter(_item => _item?.index !== item?.index))
                          }
                        }
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell style={{ minWidth: "70px" }}>
                    {item?.GroupKey}
                  </Table.Cell>
                  <Table.Cell style={{ minWidth: "70px" }}>
                    {item?.MetadataValue}
                  </Table.Cell>
                  {!selectedType?.toLowerCase()?.includes("global") ? (
                    <Table.Cell style={{ minWidth: "70px" }}>
                      {item?.Associations?.length > 0 ? item?.Associations?.map((associationAccount, _i) => {
                        let getAccountDetails = accounts?.find((buAccountDetails) => buAccountDetails?.accountName === associationAccount)
                        return (
                          <Popup key={_i} content={<p style={{ fontSize: "10px" }}>{getAccountDetails?.value}</p>} trigger={<Label style={{ margin: "5px" }}>{associationAccount}</Label>} />
                        )
                      }) : ('N/A')}
                    </Table.Cell>
                  ) : null}
                  <Table.Cell style={{ minWidth: "70px" }}>
                    {item?.MetadataKey?.toLowerCase()?.includes("global")
                      ? (item?.MetadataKey?.toLowerCase()?.includes("iamglobal") ? 'IAM Global' : 'S3 Global')
                      : item?.MetadataKey?.toLowerCase()?.includes("iamtrus") ? 'IAM Local' : 'S3 Local'}
                  </Table.Cell>
                </Table.Row>
              )) : (
                <Table.Row>
                  {(metadataStore?.localIamPartner?.isLoading && metadataStore?.globalS3Partner?.isLoading) ? (<Table.Cell colSpan={`colSpan=${2}`} >
                    Loading...
                  </Table.Cell>) : (<Table.Cell colSpan={`colSpan=${2}`} >
                    No records found!
                  </Table.Cell>)}
                </Table.Row>
              )
            }
          </Table.Body>
        </Table>
      </div>
      <div style={{ marginTop: "10px", width: "100%", display: "flex", justifyContent: "space-between" }}>
        <Pagination
          activePage={currentPage}
          totalPages={Math.ceil(_currentData.length / dataPerPage)}
          onPageChange={(event, data) => {
            setCurrentPage(data.activePage)
          }}
          firstItem={{ content: <Icon name='angle double left' />, icon: true }}
          lastItem={{ content: <Icon name='angle double right' />, icon: true }}
          prevItem={{ content: <Icon name='angle left' />, icon: true }}
          nextItem={{ content: <Icon name='angle right' />, icon: true }}
        />
        <h3>Results:{`(${_currentData?.length})`}</h3>
      </div>
    </React.Fragment>
  )
  return (
    <React.Fragment>
      <Segment
        size="mini"
        style={{
          padding: "30px",
          borderColor: "blue",
          borderStyle: "Solid",
          margin: "6px",
        }}>
            <Dimmer active={
                metadataStore?.localIamPartner?.isLoading ||
                metadataStore?.globalIamPartner?.isLoading ||
                metadataStore?.localS3Partnerr?.isLoading ||
                metadataStore?.globalS3Partner?.isLoading 
              } inverted>
            <Loader/>
          </Dimmer>
        <div style={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ width: "300px" }}>
            <Header as="h3" color="blue">Trusted Partners Report</Header>
          </div>
          <CloudModal openModal={openModal} selectCloud={selectCloud} setOpenModal={setOpenModal} setSelectCloud={setSelectCloud} />
        </div>
        <Grid id="result" style={{ height: "100vh" }}>
          <Grid.Row>
            <Grid.Column width={3} style={{ display: toggleTableView ? "block" : "none" }}>
              <FilterBox
                accounts={accounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                setSelectedType={setSelectedType}
                selectedType={selectedType}
                partnersOption={partnersOption}
                setPartnerName={setPartnerName}
                copyMetadata={copyMetadata}
                ruleName={ruleName}
                setRuleName = {setRuleName}
                pdfRuleName={pdfRuleName}
                setPdfRuleName={setPdfRuleName}
                currentData={_currentData}
              />
            </Grid.Column>
            <Grid.Column width={toggleTableView ? 13 : 16}>
              <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <Button style={{ marginTop: "20px" }} basic icon={toggleTableView ? "arrow left" : "filter"} onClick={() => {
                    setToggleTableView(!toggleTableView)
                  }} />
                  <Input
                    placeholder="Search..."
                    icon="search"
                    onChange={(e, data) => {
                      if (storeCheckData?.length > 0) {
                        setStoreCheckData([])
                        setDownloadMarkedAllData(false)
                        setDownloadMarkedDataRecord([])
                      }
                      setCurrentPage(1)
                      setSearch(data.value)
                    }}
                    style={{ width: "220px", marginTop: "20px" }}
                  />
                </div>
                <div style={{ display: 'flex', width: "100%", justifyContent: "flex-end" }}>
                  <div >
                    <Dropdown
                      placeholder='Record per page'
                      selection
                      options={dataPerPageOptions}
                      value={dataPerPage}
                      onChange={(e, data) => {
                        setDataPerPage(data.value)
                        setCurrentPage(1)
                      }}
                      style={{ height: "35px", marginTop: "20px", minWidth: 0 }}
                    />
                  </div>
                  <div style={{ marginLeft: "5px", marginTop: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Popup
                      content="Download results to CSV"
                      trigger={<Button disabled={currentData.length === 0} basic onClick={() => {
                        let downloadData = [];
                        if (storeCheckData.length > 0 && search === "") {
                          downloadData = storeCheckData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        } else {
                          downloadData = _currentData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }
                        handleDownloadCsv(downloadData, 'partner_report');
                      }} style={{ padding: "1px", height: "36px", width: "40px" }}>
                        <Image src={csvIcon} style={{ marginLeft: "13px", height: "13.5px" }} />
                      </Button>}
                    />
                    <Popup
                      content="Download results to PDF"
                      trigger={<Button disabled={currentData.length === 0} basic icon="file pdf" onClick={() => {
                        let downloadData = [];
                        if (storeCheckData.length > 0 && search === "") {
                          downloadData = storeCheckData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }
                        else {
                          downloadData = _currentData?.map((item) => {
                            const {
                              index, LastModifiedBy, CreateTimeStamp, UpdateTimeStamp
                              , ...rest } = item
                            return rest
                          })
                        }

                        handleDownloadPdf(downloadData, tableHeader, `partner_report_${Date.now()}`)
                      }} />}
                    />
                  </div>
                </div>
              </div>
              <RenderTableView />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </React.Fragment>
  )
}
