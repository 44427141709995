import React from 'react';
import { Modal, Icon, Button } from 'semantic-ui-react';

export const SuccessModal = ({ open, onClose, message }) => {
  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>
        <Icon name="check circle" color="green" /> Success
      </Modal.Header>
      <Modal.Content>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Icon name="check circle outline" color="green" size="huge" />
          <p style={{ marginTop: '15px', fontSize: '16px' }}>{message}</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={onClose}>
          <Icon name="checkmark" /> OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const ErrorModal = ({ open, onClose, message }) => {
  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>
        <Icon name="times circle" color="red" /> Error
      </Modal.Header>
      <Modal.Content>
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Icon name="times circle outline" color="red" size="huge" />
          <p style={{ marginTop: '15px', fontSize: '16px' }}>{message}</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>
          <Icon name="times" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
